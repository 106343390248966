import { OrbitalStore } from "orbital_common_components";
import countries from "i18n-iso-countries";
import _ from "lodash";

const cityComponent = "administrative_area_level_3";
const countryComponent = "country";
const postalCodeComponent = "postal_code";

export function getStaticOrbitalLogo() {
    var orbitalLogo = require("../assets/img/orbitalLogo.png");
    if (_.isEmpty(orbitalLogo) == false) {
        orbitalLogo = orbitalLogo.default;
    }
    return orbitalLogo;
}

export function getOrbitalLanguageOptions() {
    var orbitalConfig = OrbitalStore.getOrbitalConfig();
    var languages = orbitalConfig.preferedlang || [];
    languages = _.sortBy(languages, "label");
    return languages;
}

export function getCityFromAddressComponents(address_components) {
    var addressComponent = _.find(address_components, component => {
        var types = component.types;
        if (types.indexOf(cityComponent) !== -1) {
            return component;
        }
    });
    var city = addressComponent ? addressComponent.long_name : null;
    return city;
}

export function getCountryFromAddressComponents(address_components) {
    var addressComponent = _.find(address_components, component => {
        var types = component.types;
        if (types.indexOf(countryComponent) !== -1) {
            return component;
        }
    });
    var countryCode = addressComponent ? addressComponent.short_name : null;
    var countryAlpha3 = countries.alpha2ToAlpha3(countryCode);
    return countryAlpha3;
}

export function getPostalCodeFromAddressComponents(address_components) {
    var addressComponent = _.find(address_components, component => {
        var types = component.types;
        if (types.indexOf(postalCodeComponent) !== -1) {
            return component;
        }
    });
    var postalCode = addressComponent ? addressComponent.long_name : null;
    return postalCode;
}

export function getUrlParam(param) {
    var urlParams = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            urlParams[key] = value;
        });
    return urlParams[param];

    // function gup(name, url) {
    //     name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    //     var regexS = "[\\?&]" + name + "=([^&#]*)";
    //     var regex = new RegExp(regexS);
    //     var results = regex.exec(url);
    //     return results == null ? null : results[1];
    // }
}
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { OrbitalCancelIcon } from "orbital_common_components";
import { AuthStore, OrbitalStore, SessionStorageStore } from "orbital_common_components";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

export default function Agreements(props) {
    const { hideCancelButton, showAcceptButton, onCancel, agreementType } = props;
    const defaulLang = AuthStore.getDefautlLang() || "En";

    const auth = AuthStore.getAuth();
    const systemUserRole = auth.systemUserRole;
    const postgresOrbitalId = auth._postgresOrbitalId;

    const orbitalConfig = OrbitalStore.getOrbitalConfig() || {};
    const orbitalAgreementText = (orbitalConfig.orbitalAgreement || {})[defaulLang];
    const orbitalPrivacyText = (orbitalConfig.orbitalPrivacy || {})[defaulLang];

    const [loading, setLoading] = useState(false);
    const [isScrolledTermsAndConditions, setIsScrolledTermsAndConditions] = useState(false);
    const [isScrolledPrivacy, setIsScrolledPrivacy] = useState(false);
    const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

    /*************************************************************************/
    /**************************** STANDARD ***********************************/
    /*************************************************************************/
    useEffect(() => {
        if (systemUserRole !== "root") {
            checkTermsAndConditions();
        }
    }, [])

    useEffect(() => {
        if (acceptedTermsAndConditions === true) {
            checkPrivacyPolicy();
        }
    }, [acceptedTermsAndConditions])

    /*************************************************************************/
    /*************************** FUNCTIONS ***********************************/
    /*************************************************************************/
    function checkTermsAndConditions() {
        if (_.isEmpty(postgresOrbitalId.orbitalAgreement) === false) {
            setAcceptedTermsAndConditions(true);
        } else {
            const orbitalAgreementDiv = document.getElementById("orbital_agreement");
            orbitalAgreementDiv.addEventListener("scroll", event => {
                const scrollTop = orbitalAgreementDiv.scrollTop;
                const scrollHeight = orbitalAgreementDiv.scrollHeight;
                const clientHeight = orbitalAgreementDiv.clientHeight;
                if ((scrollHeight - Math.ceil(scrollTop)) < (clientHeight + 300)) {
                    setIsScrolledTermsAndConditions(true);
                }
            }, { passive: true });
        }
    }

    function checkPrivacyPolicy() {
        if (_.isEmpty(postgresOrbitalId.privacyPolicyAgreement) === false) {
            setAcceptedPrivacyPolicy(true);
        } else {
            const privacyPolicyDiv = document.getElementById("privacy_policy");
            privacyPolicyDiv.scrollTop = 0;
            privacyPolicyDiv.addEventListener("scroll", event => {
                const scrollTop = privacyPolicyDiv.scrollTop;
                const scrollHeight = privacyPolicyDiv.scrollHeight;
                const clientHeight = privacyPolicyDiv.clientHeight;

                if ((scrollHeight - Math.ceil(scrollTop)) < (clientHeight + 300)) {
                    setIsScrolledPrivacy(true);
                }
            }, { passive: true });
        }
    }

    function updateAgreements() {
        const { orbitalAgreement, privacyPolicyAgreement } = postgresOrbitalId;

        postgresOrbitalId.orbitalAgreement = _.isEmpty(orbitalAgreement) ? moment().utc().toISOString() : orbitalAgreement;
        postgresOrbitalId.privacyPolicyAgreement = _.isEmpty(privacyPolicyAgreement) ? moment().utc().toISOString() : privacyPolicyAgreement;

        setLoading(true);
        SpecificAPI.updatePostgresOrbitalIdById(postgresOrbitalId)
            .then((data) => {
                auth._postgresOrbitalId = data;
                SessionStorageStore.setAuth(auth);
                AuthStore.setAuth(auth);
                toast.success(localization.agreementsSavedSuccessfully || "Terms And Conditions saved successfully");
                onCancel();
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorSavingAgreements || "Error saving Terms And Conditions");
                setLoading(false);
            })
    }

    /*************************************************************************/
    /****************************** RENDER ***********************************/
    /*************************************************************************/
    function parseTitle() {
        if (agreementType === "termsAndConditions" && systemUserRole === "root") {
            return localization.termsAndConditions || "Terms & Conditions";
        } else if (agreementType === "privacyPolicy" && systemUserRole === "root") {
            return localization.privacyPolicy || "Privacy Policy";
        } else if (agreementType === "termsAndConditions" || acceptedTermsAndConditions === false) {
            return localization.termsAndConditions || "Terms & Conditions";
        } else {
            return localization.privacyPolicy || "Privacy Policy";
        }
    }

    function parseBody() {
        if (agreementType === "termsAndConditions" && systemUserRole === "root") {
            return <div id={"orbital_agreement"} className="orbital_agreement">
                <div dangerouslySetInnerHTML={{ __html: orbitalAgreementText }} />
            </div>
        } else if (agreementType === "privacyPolicy" && systemUserRole === "root") {
            return <div id={"privacy_policy"} className="orbital_agreement">
                <div dangerouslySetInnerHTML={{ __html: orbitalPrivacyText }} />
            </div>
        } else if (agreementType === "termsAndConditions" || acceptedTermsAndConditions === false) {
            return <div id={"orbital_agreement"} className="orbital_agreement">
                <div dangerouslySetInnerHTML={{ __html: orbitalAgreementText }} />

            </div>
        } else {
            return <div id={"privacy_policy"} className="orbital_agreement">
                <div dangerouslySetInnerHTML={{ __html: orbitalPrivacyText }} />
            </div>
        }
    }

    function parseCheckbox() {
        if (acceptedTermsAndConditions === false) {
            return <Form.Check
                label={localization.agreeTermsAndConditions || "I agree to the Terms & Conditions"}
                type="checkbox"
                className="custom_checkbox"
                disabled={isScrolledTermsAndConditions === false}
                checked={acceptedTermsAndConditions || false}
                onChange={() => { setAcceptedTermsAndConditions(true) }}>
            </Form.Check>
        } else {
            return <Form.Check
                label={localization.agreePrivacyPolicy || "I agree to the Privacy Policy"}
                type="checkbox"
                className="custom_checkbox"
                disabled={isScrolledPrivacy === false}
                checked={acceptedPrivacyPolicy || false}
                onChange={() => { setAcceptedPrivacyPolicy(true) }}>
            </Form.Check>
        }
    }

    return (
        <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
            <Card>
                <Card.Header>
                    <b>{parseTitle()}</b>
                    <span style={{ float: "right" }}>
                        {hideCancelButton !== true &&
                            <OrbitalCancelIcon tooltip={localization.close || "Close"} onClick={() => { onCancel() }}></OrbitalCancelIcon>
                        }
                    </span>
                </Card.Header>
                <Card.Body>
                    {parseBody()}
                </Card.Body>
                {showAcceptButton &&
                    <Card.Footer>
                        <Row>
                            <Col sm={6}>
                                {parseCheckbox()}
                            </Col>
                            <Col sm={6}>
                                <div style={{ float: "right" }}>
                                    <Button
                                        disabled={acceptedTermsAndConditions === false || acceptedPrivacyPolicy === false}
                                        variant="primary"
                                        onClick={updateAgreements}>
                                        {localization.submit || "Submit"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Footer>
                }
            </Card>
        </LoadingOverlay>
    )
}
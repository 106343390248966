// import React from "react";
// import ReactDOM from "react-dom";

// import App from "./app.js";

// ReactDOM.render(<App />, document.getElementById("root"));

import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from "./app.js";

const root = createRoot(document.getElementById('root'));
root.render(<Router><App /></Router>);
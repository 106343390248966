import React, { useEffect } from "react";

import PluginLoader from "@views/plugins/PluginLoader";


const PLUGIN_KEY = "br_blog_premium";
export default function BlogPremiumPlugin() {
    useEffect(() => {
        PluginLoader(PLUGIN_KEY);
    }, [])

    return (
        <div id={PLUGIN_KEY}> </div>
    );
}

import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { LoadingOverlay } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { loadStripe } from '@stripe/stripe-js';
import _ from "lodash";

import SpecificAPI from "../../../services/SpecificAPI";
import localization from "../../../services/localization";
import * as Utils from "../../../services/Utils";

const orbitalLogo = Utils.getStaticOrbitalLogo();

class StripeCheckout extends Component {
    constructor() {
        super();
        this.state = {
            error: false,
            loading: true,
            data: {}
        }
    }
    /*************************************************************************/
    /************************ STANDARD ***************************************/
    /*************************************************************************/
    componentDidMount() {
        var self = this;
        var promise = null;


        var sessionId = Utils.getUrlParam('sessionId');
        var brandId = Utils.getUrlParam('brandId');
        var activityId = Utils.getUrlParam('activityId');
        if (_.isEmpty(sessionId) == true) {
            console.error("Missing 'sessionId' parameter inside url!")
            self.setState({
                error_message: "Missing 'sessionId' parameter inside url!",
                error: true,
                loading: false
            })
        } else if (_.isEmpty(brandId) == true && _.isEmpty(activityId) == true) {
            console.error("Missing both 'brandId' and 'activityId' parameters inside url!")
            self.setState({
                error_message: "Missing both 'brandId' and 'activityId' parameters inside url!",
                error: true,
                loading: false
            })
        } else {
            if (brandId) {
                promise = SpecificAPI.getBrandPaymentConfig(brandId);
            } else if (activityId) {
                promise = SpecificAPI.getActivityPaymentConfig(activityId);
            }
        }

        if (promise != null) {
            promise
                .then(async (data) => {
                    var stripe = data.stripe || {};
                    var properties = stripe.properties || {};
                    var publicKey = properties.publicKey;
                    if (publicKey) {
                        var stripe = await loadStripe(publicKey);
                        var { error } = await stripe.redirectToCheckout({ sessionId });
                        if (error) {
                            console.error("Error occured for Stripe Checkout ", error);
                            self.setState({
                                error: true,
                                loading: false
                            })
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error occured for Stripe Checkout ", error);
                    self.setState({
                        error: true,
                        loading: false
                    })
                })
        }
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    render() {
        var { loading, error, error_message, data } = this.state;

        return (
            <Row className="justify-content-center login_card">
                <Col sm="3">
                    <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col className="login_logo_container">
                                        <img alt="" className="login_logo" src={orbitalLogo} />
                                    </Col>
                                </Row>
                                {error == false &&
                                    <Row className="margin_top_row">
                                        <Col>
                                            {JSON.stringify(data)}
                                        </Col>
                                    </Row>
                                }
                                {error == true &&
                                    <Row className="margin_top_row">
                                        <Col className="margin_top_row">
                                            <center>
                                                <FontAwesomeIcon icon={faExclamationTriangle} /> {localization.checkoutErrorOccurred_stripe || "Stripe checkout error occurred"}!
                                            </center>
                                            <center className="margin_top_row">
                                                {error_message}
                                            </center>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </LoadingOverlay>
                </Col>
            </Row>


            // <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
            //     {error == false &&
            //         <div style={{ height: "100vh", color: "white" }}>
            //             {JSON.stringify(this.state.data)}
            //         </div>
            //     }
            //     {error == true &&
            //         <>
            //             <div>
            //                 <center>
            //                     Stripe checkout error occurred!
            //                 </center>
            //             </div>
            //             <div>
            //                 <center>
            //                     {error_message}
            //                 </center>
            //             </div>
            //         </>
            //     }
            // </LoadingOverlay>
        )
    }
}

export default StripeCheckout;
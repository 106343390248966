import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { LoadingOverlay } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import localization from "../../../services/localization";
import * as Utils from "../../../services/Utils";

const orbitalLogo = Utils.getStaticOrbitalLogo();

class PaypalCheckout extends Component {
    constructor() {
        super();
        this.state = {
            error: false,
            loading: true,
            data: {}
        }
    }

    /*************************************************************************/
    /************************ STANDARD ***************************************/
    /*************************************************************************/
    componentDidMount() {
        var self = this;

        var options = {
            method: "get",
            url: "http://localhost:4242/orbitalPaypalCheckout",
            params: {
                amount: 1,
                currency: "eur",
                bookingId: "12345"
            }
        }

        axios(options)
            .then(async (result) => {
                var data = result.data;
                var url = data.url || data;
                window.location.href = url
            })
            .catch((error) => {
                console.error(error);
                self.setState({
                    error: true
                })
            })
            .finally(() => {
                self.setState({
                    loading: false
                })
            })
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    render() {
        var { loading, error, data } = this.state;

        return (
            <Row className="justify-content-center login_card">
                <Col sm="3">
                    <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col className="login_logo_container">
                                        <img alt="" className="login_logo" src={orbitalLogo} />
                                    </Col>
                                </Row>
                                {error == false &&
                                    <Row className="margin_top_row">
                                        <Col>
                                            {JSON.stringify(data)}
                                        </Col>
                                    </Row>
                                }
                                {error == true &&
                                    <Row className="margin_top_row">
                                        <Col className="margin_top_row">
                                            <center>
                                                <FontAwesomeIcon icon={faExclamationTriangle} /> {localization.checkoutErrorOccurred_paypal || "Paypal checkout error occurred"}!
                                            </center>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </LoadingOverlay>
                </Col>
            </Row>
            //         {
            //     error == false &&
            //     <div style={{ height: "100vh", color: "white" }}>
            //         {JSON.stringify(this.state.data)}
            //     </div>
            // }
            // {
            //     error == true &&
            //     <div>
            //         <center>
            //             Stripe checkout error occurred!
            //         </center>
            //     </div>
            // }
        )
    }
}

export default PaypalCheckout;
import LoadingOverlay from 'react-loading-overlay-ts';

export default function CustomLoadingOverlay(props) {
    const { text, active, children } = props;
    return (
        <LoadingOverlay
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(24, 144, 255, 0.5)'
                })
            }}
            active={active}
            spinner
            text={text}>
            {props.children}
        </LoadingOverlay>
    )
}
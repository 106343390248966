import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { OrbitalErrorDiv } from "orbital_common_components";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import owasp from "owasp-password-strength-test";
import * as yup from "yup";
import _ from "lodash";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";
import * as Utils from "@services/Utils";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

export default function ResetPassword() {
    const navigate = useNavigate();

    const orbitalLogo = Utils.getStaticOrbitalLogo();
    const email = Utils.getUrlParam('email');
    const resetToken = Utils.getUrlParam('token');

    const [loading, setLoading] = useState(false);
    const [owaspError, setOwaspError] = useState(false);

    const capchaValidity = {
        name: "capchaValidation",
        message: localization.capchaIsNotValid || "Capcha is not valid",
        test: (value, schema) => {
            const { capcha } = schema.parent;
            const test = validateCaptcha(capcha);
            return test;
        }
    }

    const passwordEquality = {
        name: "passwordEquality",
        message: localization.passwordEquality || "Passwords have to be equal",
        test: (value, schema) => {
            const { newPassword, confirmPassword } = schema.parent;
            if (newPassword === confirmPassword) {
                return true;
            }
        }
    }

    const validationSchema = yup.object().shape({
        newPassword: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
            .test(passwordEquality),
        confirmPassword: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
            .test(passwordEquality),
        capcha: yup.string()
            .typeError(localization.completeField || "Please complete the field")
            .required(localization.completeField || "Please complete the field")
            .test(capchaValidity)
    })

    /*************************************************************************/
    /************************** FUNCTIONS ************************************/
    /*************************************************************************/
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/
    function setPassword(values) {
        const { newPassword, confirmPassword } = values;

        setLoading(true);
        SpecificAPI.resetPassword(email, newPassword, resetToken)
            .then(() => {
                toast.success(localization.passwordResetSuccessfully || "Password reset successfully successfully");
                navigate("/login")
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorResettingPassword || "Error resetting password");
                setLoading(false);
            })
    }

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/
    function save(validateForm, handleSubmit, values, errors, setErrors) {
        validateForm()
            .then((errorData) => {
                if (_.isEmpty(errorData) === false) {
                    toast.warn(localization.checkFieldsValidity || "Check fields validity");
                    handleSubmit();
                }
                else {
                    const { newPassword } = values;
                    const result = owasp.test(newPassword);
                    const errors = result.errors;
                    if (errors.length > 0) {
                        console.error(result)
                        setOwaspError(true);
                    } else {
                        setOwaspError(false);
                        handleSubmit();
                    }
                }
            })
    }

    return (
        <Row className="justify-content-center login_card">
            <Col sm="3">
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        setPassword(values);
                    }}
                    validateOnChange={false}
                    initialValues={{
                        newPassword: "",
                        confirmPassword: ""
                    }}>
                    {({ handleSubmit, handleChange, values, isValid, errors, setFieldValue, setValues, validateForm, setErrors }) => (
                        <LoadingOverlay
                            active={loading}
                            text={(localization.loading || "Loading") + "..."}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col className="login_logo_container">
                                            <img alt="" className="login_logo" src={orbitalLogo} />
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col>
                                            <Form.Control
                                                type="password"
                                                name="newPassword"
                                                isInvalid={errors.newPassword || owaspError}
                                                placeholder={localization.newPassword || "Password"}
                                                value={values.newPassword || ""}
                                                onChange={handleChange}>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col>
                                            <Form.Control
                                                type="password"
                                                name="confirmPassword"
                                                isInvalid={errors.confirmPassword || owaspError}
                                                placeholder={localization.confirmPassword || "Password"}
                                                value={values.confirmPassword || ""}
                                                onChange={handleChange}>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    {owaspError === true &&
                                        <React.Fragment>
                                            <OrbitalErrorDiv>{localization.tenCharacters || "The password must be at least 10 characters long"}</OrbitalErrorDiv>
                                            <OrbitalErrorDiv>{localization.atLeastOneLowercase || "The password must contain at least one lowercase letter"}</OrbitalErrorDiv>
                                            <OrbitalErrorDiv>{localization.atLeastOneUppercase || "The password must contain at least one uppercase letter"}</OrbitalErrorDiv>
                                            <OrbitalErrorDiv>{localization.atLeastOneNumber || "The password must contain at least one number"}</OrbitalErrorDiv>
                                            <OrbitalErrorDiv>{localization.atLeastOneSpecialChar || "The password must contain at least one special character"}</OrbitalErrorDiv>
                                            <OrbitalErrorDiv>{localization.noRepeatedChar || "The password may not contain sequences of three or more repeated characters"}</OrbitalErrorDiv>
                                        </React.Fragment>
                                    }
                                    <Row className="margin_top_row">
                                        <Col sm={6}>
                                            <LoadCanvasTemplate />
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Control
                                                name={"capcha"}
                                                isInvalid={errors.capcha}
                                                placeholder={localization.capcha || "Capcha"}
                                                value={values.capcha || ""}
                                                onChange={handleChange}>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.capcha}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col sm={12}>
                                            <Button style={{ width: "100%" }} variant="primary" onClick={() => { save(validateForm, handleSubmit, values, errors, setErrors) }}>
                                                {localization.resetPassword || "Reset password"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </LoadingOverlay>
                    )}
                </Formik>
            </Col>
        </Row>
    )
}
import React, { useEffect } from "react";

import PluginLoader from "@views/plugins/PluginLoader";

const PLUGIN_KEY = "ow_mentor";
export default function Mentor() {
  useEffect(() => {
    PluginLoader(PLUGIN_KEY);
  }, [])

  return (
    <div id={PLUGIN_KEY}> </div>
  );
}

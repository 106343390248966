import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcStripe } from "@fortawesome/free-brands-svg-icons";

import localization from "../../../services/localization";
import * as Utils from "../../../services/Utils";

const orbitalLogo = Utils.getStaticOrbitalLogo();

class StripeCheckoutCancel extends Component {
    render() {
        return (
            <Row className="justify-content-center login_card">
                <Col sm="3">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="login_logo_container">
                                    <img alt="" className="login_logo" src={orbitalLogo} />
                                </Col>
                            </Row>
                            <Row className="margin_top_row">
                                <Col className="margin_top_row">
                                    <center>
                                        <div className="payment_brand_logo">
                                            <FontAwesomeIcon icon={faCcStripe}></FontAwesomeIcon>
                                        </div>
                                        <div className="margin_top_row">
                                            {localization.paymentCanceled_stripe || "Stripe payment canceled"}!
                                        </div>
                                    </center>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}
export default StripeCheckoutCancel;
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Tooltip } from "orbital_common_components";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";
import * as Utils from "@services/Utils";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";


const capchaValidity = {
    name: "capchaValidation",
    message: localization.capchaIsNotValid || "Capcha is not valid",
    test: (value, schema) => {
        const { capcha } = schema.parent;
        const test = validateCaptcha(capcha);
        return test;
    }
}

const validationSchema = yup.object().shape({
    email: yup.string()
        .typeError(localization.completeField || "Please complete the field")
        .required(localization.completeField || "Please complete the field")
        .email(localization.insertValidEmail || "Insert a valid email address"),
    capcha: yup.string()
        .typeError(localization.completeField || "Please complete the field")
        .required(localization.completeField || "Please complete the field")
        .test(capchaValidity)
})

export default function ForgotPassword() {
    const navigate = useNavigate();
    const orbitalLogo = Utils.getStaticOrbitalLogo();

    const [loading, setLoading] = useState(false);

    /*************************************************************************/
    /************************** FUNCTIONS ************************************/
    /*************************************************************************/
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    /*************************************************************************/
    /************************** FUNCTIONS ************************************/
    /*************************************************************************/
    function resetPassword(email) {
        const trimmedEmail = email ? email.trim() : "";

        setLoading(true);
        SpecificAPI.sendResetPasswordLink(email)
            .then(() => {
                toast.success(localization.resetPasswordLinkSentSuccessfully || "Reset password link sent successfully");
                navigate("/login");
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorSendingResetPasswordLink || "Error sending reset password link");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    function validateFormBeforeSave(validateForm, handleSubmit) {
        validateForm().then((errorData) => {
            if (_.isEmpty(errorData) === false) { toast.warn(localization.checkFieldsValidity || "Check fields validity") }
        })
        handleSubmit();
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    return (
        <Row className="justify-content-center login_card">
            <Col sm="3">
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        const {email} = values;
                        resetPassword(email);
                    }}
                    validateOnChange={false}
                    initialValues={{
                        username: null,
                        password: null
                    }}>
                    {({ handleSubmit, handleChange, values, isValid, errors, setFieldValue, setValues, validateForm }) => (
                        <LoadingOverlay
                            active={loading}
                            text={(localization.loading || "Loading") + "..."}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Tooltip tooltip={localization.goBack || "Go back"}>
                                                <FontAwesomeIcon className="go_back" icon={faArrowAltCircleLeft} onClick={() => { navigate("/login"); }} />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="login_logo_container">
                                            <img alt="" className="login_logo" src={orbitalLogo} />
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col>
                                            <Form.Control
                                                name={"email"}
                                                isInvalid={errors.email}
                                                placeholder={localization.email || "Email"}
                                                value={values.email || ""}
                                                onChange={handleChange}>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col sm={6}>
                                            <LoadCanvasTemplate />
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Control
                                                name={"capcha"}
                                                isInvalid={errors.capcha}
                                                placeholder={localization.capcha || "Capcha"}
                                                value={values.capcha || ""}
                                                onChange={handleChange}>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.capcha}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className="margin_top_row">
                                        <Col sm={12}>
                                            <Button style={{ width: "100%" }} variant="primary" onClick={() => { validateFormBeforeSave(validateForm, handleSubmit) }}>
                                                {localization.sendResetLink || "Send reset link"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </LoadingOverlay>
                    )}
                </Formik>
            </Col>
        </Row>
    )
}
import React, { useEffect, useState } from "react";
import { CommonUtils, OrbitalCancelIcon } from "orbital_common_components";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

export default function LoginAgreements(props) {
    const { agreementType, onCancel } = props;
    const lang = CommonUtils.getInitialLocalizationLanguage();

    const [loading, setLoading] = useState(true);
    const [agreementText, setAgreementText] = useState(null);

    useEffect(() => {
        var promise = agreementType === "termsAndConditions" ? SpecificAPI.getTermsAndConditions() : SpecificAPI.getPrivacyPolicy();
        promise
            .then((data) => {
                const langText = data[lang];
                setAgreementText(langText);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorLoadingData || "Error loading data");
                onCancel();
            })
    }, []);


    function parseTitle() {
        if (agreementType === "termsAndConditions") {
            return localization.termsAndConditions || "Terms & Conditions";
        } else {
            return localization.privacyPolicy || "Privacy Policy";
        }
    }

    return (
        <LoadingOverlay active={loading} text={(localization.loading || "Loading") + "..."}>
            <Card>
                <Card.Header>
                    <b>{parseTitle()}</b>
                    <span style={{ float: "right" }}>
                        <OrbitalCancelIcon tooltip={localization.close || "Close"} onClick={() => { onCancel() }}></OrbitalCancelIcon>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div id={"orbital_agreement"} className="orbital_agreement">
                        <div dangerouslySetInnerHTML={{ __html: agreementText }} />
                    </div>
                </Card.Body>
            </Card>
        </LoadingOverlay>
    )
}
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

export const SettingBtnDiv = styled.div`
    float: right;
    padding-top: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.3rem;
`;

export const AnnouncementBtnDiv = styled.div`
    float: right;
    padding-top: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.3rem;
`;

export const WelcomeDiv = styled.div`
    font-size: 0.8rem;
    padding-top: 0.7rem;
`;

export const DefaultLayoutHeader = styled.div`
    position: relative;
    width: 100vw;
    min-height: 5vh;
    border-bottom: 1px solid #c8ced3;
`;

const CollapseButtonContainer = styled.div`
    padding-top: 0.3rem;
    padding-left: 0.3rem;
    padding-bottom: 0.3rem;
    width: 10vw;
`;

export const CollapseButton = (props) => {
    return <CollapseButtonContainer>
        <Row>
            <Col>
                <Button variant="outline-secondary" onClick={props.onClick}><FontAwesomeIcon icon={faBars} ></FontAwesomeIcon></Button>
            </Col>
        </Row>
    </CollapseButtonContainer>
}
import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Modal, Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { AuthStore, SessionStorageStore, OrbitalCancelIcon, Tooltip } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faExclamation, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";

export default function Announcements() {
  const currentLang = SessionStorageStore.getCurrentLang() || AuthStore.getDefautlLang();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  /*************************************************************************/
  /*************************** STANDARD ************************************/
  /*************************************************************************/
  useEffect(() => {
    getAnnouncements();
  }, [])

  /*************************************************************************/
  /*************************** STANDARD ************************************/
  /*************************************************************************/
  function getAnnouncements() {
    setLoading(true);
    setError(false);
    SpecificAPI.getAnnouncementsByTarget(AuthStore.getUserRole())
      .then(data => {
        setError(false);
        setAnnouncements(data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setAnnouncements([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  /*************************************************************************/
  /***************************** RENDER ************************************/
  /*************************************************************************/
  function getTitleIcon() {
    if (loading === true) {
      return <FontAwesomeIcon icon={faSync} spin />
    } else {
      return <FontAwesomeIcon icon={faBell} />
    }
  }

  function renderAnnouncements() {
    return <React.Fragment>
      {announcements.length === 0 &&
        <Row>
          <Col sm={12}>
            <center className="noAnnouncement"><FontAwesomeIcon icon={faInfoCircle} /> {localization.noNewAnnouncements || "No new announcements"}</center>
          </Col>
        </Row>
      }
      {announcements.length > 0 &&
        <React.Fragment>
          {_.map(announcements, (announcement, idx) => {
            return <ListGroup.Item key={idx} className="announcementItem" onClick={() => {
              setSelectedAnnouncement(announcement);
              setShowDetailModal(true);
            }}>
              <Row>
                <Col sm={8}>
                  <b>{announcement.title[currentLang]}</b>
                  <br />
                  <span>{announcement.message[currentLang]}</span>
                </Col>
                <Col sm={4}></Col>
                <Col sm={12}>
                  <small style={{ float: "right" }}>{moment(announcement.modified).fromNow()}</small>
                </Col>
              </Row>
            </ListGroup.Item>
          })}
        </React.Fragment>
      }
    </React.Fragment>
  }

  return (
    <React.Fragment>
      <div className="announcementDropDown">
        {error === true && loading === false &&
          <Tooltip tooltip={localization.errorAnnouncements || "Error loading annoucements; click to refresh."}>
            <Button variant="outline-primary" onClick={getAnnouncements}><FontAwesomeIcon icon={faExclamation} /></Button>
          </Tooltip>
        }
        {error === false &&
          <DropdownButton align="start" variant="outline-primary" title={getTitleIcon()} disabled={loading === true}>
            <Dropdown.Header><center><div>{localization.announcements || "Announcements"}</div></center></Dropdown.Header>
            <ListGroup>
              {renderAnnouncements()}
            </ListGroup>
          </DropdownButton>
        }
        <Modal show={showDetailModal}>
          <Card>
            <Card.Header>
              <b>{localization.announcements || "Announcements"}</b>
              <span style={{ float: "right" }}>
                <OrbitalCancelIcon tooltip={localization.close || "Close"} onClick={() => { setShowDetailModal(false) }}></OrbitalCancelIcon>
              </span>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={6}>
                  <small>{localization.title || "Title"} </small>
                  <h6>
                    {selectedAnnouncement?.title[currentLang]}
                  </h6>
                </Col>
                <Col sm={6}>
                  <small>{localization.date || "Date"} </small>
                  <h6>{selectedAnnouncement && moment(selectedAnnouncement.modified).format("DD/mm/yyyy hh:mm A")}</h6>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <small>Status </small>
                  <h6>{selectedAnnouncement?.status}</h6>
                </Col>
                <Col sm={6}>
                  <small>Target </small>
                  <h6>{selectedAnnouncement?.target.join(",")}</h6>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <small>{localization.message || "Message"} </small>
                  <p>{selectedAnnouncement?.message[currentLang]}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </div>
    </React.Fragment>
  )
}
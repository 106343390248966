import React, { useEffect, useState } from "react";
import { AuthStore } from "orbital_common_components";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import localization from "@services/localization";

import { SettingBtnDiv, DefaultLayoutHeader, CollapseButton } from "@views/header/HeaderStyledComponents";
// import systemPluginRoutes from "@views/plugins/systemPluginRoutes";
import systemPluginRoutes from "@views/plugins/system/systemPluginRoutes";
import Sidebar from "@views/dashboard/Sidebar";
import SettingsAdmin from "@views/header/SettingsAdmin";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

export default function DashboardAdmin() {
    const auth = AuthStore.getAuth();
    const { parent, privileges, role, sbid, systemUserRole } = auth;

    const privilegedPlugins = Object.keys(privileges)

    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [allowedRoutes, setAllowedRoutes] = useState([]);

    useEffect(() => {
        if (systemUserRole === "root") {
            setAllowedRoutes(_.cloneDeep(systemPluginRoutes));
        } else if (systemUserRole === "admin"){
            // var filteredRoutes = _.filter(routes, (route) => { _.includes(privilegedPlugins, route.pluginKey) })
            const filteredRoutes = _.filter(systemPluginRoutes, (route) => {
                return _.includes(privilegedPlugins, route.pluginKey);
            })
            setAllowedRoutes(filteredRoutes);
        }else{
            setAllowedRoutes([]);
        }
        setLoading(false);
    }, []);


    return (
        <LoadingOverlay
            active={loading}
            text={(localization.loading || "Loading") + "..."}>
            <DefaultLayoutHeader>
                <CollapseButton onClick={() => { this.setState({ collapsed: !collapsed }) }}></CollapseButton>
                <div className="defaultLayoutHeaderRight">
                    <Row>
                        <Col xs={{ span: 1, offset: 11 }} sm={{ span: 1, offset: 11 }} md={{ span: 1, offset: 11 }} lg={{ span: 1, offset: 11 }} xl={{ span: 1, offset: 11 }}>
                            <SettingBtnDiv>
                                <SettingsAdmin></SettingsAdmin>
                            </SettingBtnDiv>
                        </Col>
                    </Row>
                </div>
            </DefaultLayoutHeader>
            <Sidebar
                collapsed={collapsed}
                allowedRoutes={allowedRoutes}>
            </Sidebar>
        </LoadingOverlay>
    )
}
import React, { useEffect } from "react";

import PluginLoader from "@views/plugins/PluginLoader";

const PLUGIN_KEY = "sy_project_manager";
export default function SystemConfiguration() {
  useEffect(() => {
    PluginLoader(PLUGIN_KEY);
  }, [])

  return (
    <div id={PLUGIN_KEY}> </div>
  );
}

import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { Route, Routes, useNavigate } from "react-router-dom";
import { Tooltip, SessionStorageStore } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import _ from "lodash";

export default function Sidebar(props) {
    const { collapsed, allowedRoutes } = props;
    const navigate = useNavigate();

    const auth = SessionStorageStore.getAuthJson() || {};
    const systemUserRole = auth.systemUserRole;
    const prePath = systemUserRole === "root" || systemUserRole === "admin" ? "/dashboardAdmin" : "/dashboardUser";

    const [menuItems, setMenuItems] = useState([]);
    const [selectedPath, setSelectedPath] = useState(null);
    const [routes, setRoutes] = useState([]);

    /*************************************************************************/
    /************************** STANDARD *************************************/
    /*************************************************************************/
    useEffect(() => {
        // const sessionStorageSelectedPlugin = sessionStorage.getItem("pluginPath");
        // if (_.isEmpty(sessionStorageSelectedPlugin) === false) {
        //     choosePluginPath(sessionStorageSelectedPlugin);
        // }
    }, [])

    useEffect(() => {
        const menuItems = getMenuItems();
        setMenuItems(menuItems);

        const routes = getRoutes();
        setRoutes(routes);
    }, [allowedRoutes, collapsed, selectedPath])

    /*************************************************************************/
    /******************************* FUNCTIONS *******************************/
    /*************************************************************************/
    function getRoutes() {
        const routes = [];
        _.each(allowedRoutes, (route, idx) => {
            const { name, exact, subRoutes, pluginKey, path } = route;
            const Element = route.element;
            if (path) {
                routes.push(<Route
                    onClick={() => { debugger }}
                    key={idx}
                    path={path}
                    exact={exact}
                    name={name}
                    element={<Element />}>
                </Route>
                )
            } else if (subRoutes) {
                _.each(subRoutes, (subRoute, index) => {
                    const { name, exact, pluginKey, path } = subRoute;
                    const SubElement = subRoute.element;
                    routes.push(<Route
                        key={index}
                        path={path}
                        exact={exact}
                        name={name}
                        element={<SubElement key={path} />}>
                    </Route>
                    )
                })
            }
        })
        return routes;
    }

    /*************************************************************************/
    /******************************* FUNCTIONS *******************************/
    /*************************************************************************/
    // function loadPlugin(pluginKey) {
    //     setTimeout(() => {
    //         const element = document.getElementById(pluginKey);
    //         if (_.isEmpty(element) === false) {
    //             element.style.maxHeight = "95vh";
    //             element.style.overflow = "auto";

    //             const scriptElement = document.createElement("script");;
    //             scriptElement.type = "text/javascript";
    //             scriptElement.src = `plugins/${pluginKey}/${pluginKey}-compiled.js`;
    //             scriptElement.async = true;
    //             element.appendChild(scriptElement);
    //         }
    //     }, 5)
    // }

    function choosePluginPath(path) {
        setSelectedPath(null);
        setTimeout(() => {
            sessionStorage.setItem("pluginPath", path);
            setSelectedPath(path);

            const newPath = prePath + "/" + path;
            navigate(newPath);
        }, 5)
    }

    /*************************************************************************/
    /************************** SIDEBAR RENDER *******************************/
    /*************************************************************************/
    function getClassMenuItemClassName(path) {
        const sessionStorageSelectedPlugin = sessionStorage.getItem("pluginPath");
        const className = sessionStorageSelectedPlugin === path ? "cont_selected_path" : "";
        return className;
    }

    function getIconItem(icon, name, path) {
        const iconClassName = getClassMenuItemClassName(path);
        const iconItem = collapsed === true ? <Tooltip tooltip={name}><FontAwesomeIcon icon={icon} className={iconClassName} /></Tooltip> : <FontAwesomeIcon icon={icon} className={iconClassName} />
        return iconItem;
    }

    function getMenuItem(icon, name, parsedName, path) {
        const iconItem = getIconItem(icon, name, path);
        return (
            <MenuItem
                key={path}
                icon={iconItem}
                onClick={() => {
                    choosePluginPath(path)
                }}>
                <span className={getClassMenuItemClassName(path)}>{parsedName}</span>
            </MenuItem>
        )
    }

    function getSubMenu(icon, name, subRoutes, index) {
        const iconItem = getIconItem(icon, name);
        return <SubMenu key={index} title={collapsed === true ? null : name} icon={iconItem}>
            {_.map(subRoutes, (subRoute, subIndex) => {
                const { icon, name, path } = subRoute;
                const menuItem = getMenuItem(icon, name, name, path);
                return menuItem;
            })}
        </SubMenu>
    }

    function getMenuItems() {
        var menuItems = _.map(allowedRoutes, (route, index) => {
            const { icon, name, pluginKey, subRoutes, path } = route;

            if (_.isEmpty(subRoutes) === true) {
                const parsedName = collapsed === true ? null : name;
                const menuItem = getMenuItem(icon, name, parsedName, path);
                return menuItem;
            } else {
                const subMenu = getSubMenu(icon, name, subRoutes, index);
                return subMenu;
            }
        })
        return menuItems;
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    const containerRightClass = collapsed === true ? "containerRightCollapsed" : "containerRight";

    return (
        <div className="containerLayout">
            <div className="containerLeft">
                <ProSidebar collapsed={collapsed}>
                    <SidebarContent>
                        <Menu>
                            {menuItems}
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        {collapsed === false &&
                            <div className="orbitalSidebarFooter">
                                Orbital © {moment().format("YYYY")}
                            </div>
                        }
                    </SidebarFooter>
                </ProSidebar></div>
            <div className={containerRightClass}>
                <Routes>
                    {routes}
                </Routes>
                {/*     {_.isEmpty(selectedPath) === false &&
                    <div id={selectedPath}></div>
                }
                */}
            </div>
        </div>
    )
}
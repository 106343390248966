import { CommonUtils} from "orbital_common_components";
import LocalizedStrings from "react-localization";

const lang = CommonUtils.getInitialLocalizationLanguage();

const en = require("./languages/en.json")
const it = require("./languages/it.json")

class SingletonStrings {
  constructor() {
    this.instance = new LocalizedStrings({
      En: en,
      It: it
    });
    return this.instance;
  }
}

var ex = new SingletonStrings();
ex.setLanguage(lang)

export default ex;